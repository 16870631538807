import FinanceApi from '@/services/FinanceApi';

const report = async (config) => {
    return FinanceApi.get('/invoices/report', config);
}

const reportJob = async (config) => {
    return FinanceApi.get('/invoices/report-job', config);
}

const myInvoices = async () => {
    return FinanceApi.get('/customer/invoices');
}

const customerInvoices = async (config) => {
    return FinanceApi.get('/customer/invoices', config);
}

export default {
    report,customerInvoices,reportJob,myInvoices
}
